export const MODULE_NAME = "ClaimSampling";

export const RIGHT_QUERY = 126001;
export const RIGHT_CREATE = 126002;
export const RIGHT_UPDATE = 126003;
export const RIGHT_APPROVE = 126004;
export const APPROVED = 'APPROVED';
export const FAILED = 'FAILED';

export const TASK_STATUS = {
    RECEIVED: 'RECEIVED',
    ACCEPTED: 'ACCEPTED',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
  };