import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { POLICY_VALUES } from "../constants";

class PolicyValuesPicker extends Component {

    render() {
        return <ConstantBasedPicker
            module="policy"
            label="Policyvalues"
            constants={POLICY_VALUES}
            {...this.props}
        />
    }
}

export default PolicyValuesPicker;