import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { ETHNICITY_PICKER } from "../constants";

class EthnicityPicker extends Component {
  render() {
    return (
      <ConstantBasedPicker
        module="insuree"
        label="insuree.EthnicityPicker"
        constants={ETHNICITY_PICKER}
        {...this.props}
      />
    );
  }
}

export default EthnicityPicker;
